// DJI-Agras-T50
import imageT501 from "../images/DJI-Agras-T50/1.jpg";
import imageT502 from "../images/DJI-Agras-T50/2.jpg";
import imageT503 from "../images/DJI-Agras-T50/3.jpg";
import imageT504 from "../images/DJI-Agras-T50/4.jpg";
import imageT505 from "../images/DJI-Agras-T50/5.jpg";
import imageT506 from "../images/DJI-Agras-T50/6.jpg";
import imageT507 from "../images/DJI-Agras-T50/7.jpg";
import imageT508 from "../images/DJI-Agras-T50/8.jpg";
import imageT509 from "../images/DJI-Agras-T50/9.jpg";
import imageT510 from "../images/DJI-Agras-T50/10.jpg";
import imageT511 from "../images/DJI-Agras-T50/11.jpg";

// DJI-Matrice-M350-RTK

import imageM3501 from "../images/DJI-Matrice-350-RTK/1.jpg";
import imageM3502 from "../images/DJI-Matrice-350-RTK/2.jpg";
import imageM3503 from "../images/DJI-Matrice-350-RTK/3.jpg";
import imageM3504 from "../images/DJI-Matrice-350-RTK/4.jpg";
import imageM3505 from "../images/DJI-Matrice-350-RTK/5.jpg";
import imageM3506 from "../images/DJI-Matrice-350-RTK/6.jpg";
import imageM3507 from "../images/DJI-Matrice-350-RTK/7.jpg";
import imageM3508 from "../images/DJI-Matrice-350-RTK/8.jpg";
import imageM3509 from "../images/DJI-Matrice-350-RTK/9.jpg";

// DJI-Matrice-M300-RTK
import imageM3001 from "../images/DJI-Matrice-300-RTK/1.jpg";
import imageM3002 from "../images/DJI-Matrice-300-RTK/2.jpg";
import imageM3003 from "../images/DJI-Matrice-300-RTK/3.jpg";
import imageM3004 from "../images/DJI-Matrice-300-RTK/4.jpg";
import imageM3005 from "../images/DJI-Matrice-300-RTK/5.jpg";
import imageM3006 from "../images/DJI-Matrice-300-RTK/6.jpg";
import imageM3007 from "../images/DJI-Matrice-300-RTK/7.jpg";
// import imageM3008 from "../images/DJI-Matrice-300-RTK/8.png";
import imageM3009 from "../images/DJI-Matrice-300-RTK/9.jpg";
import imageM30010 from "../images/DJI-Matrice-300-RTK/10.jpg";
import imageM30011 from "../images/DJI-Matrice-300-RTK/11.jpg";

// Autel Alpha
import imageAA1 from "../images/Autel-Alpha/1.jpg";
import imageAA2 from "../images/Autel-Alpha/2.jpg";
import imageAA3 from "../images/Autel-Alpha/3.jpg";
import imageAA4 from "../images/Autel-Alpha/4.jpg";
import imageAA5 from "../images/Autel-Alpha/5.jpg";
import imageAA6 from "../images/Autel-Alpha/6.jpg";
import imageAA7 from "../images/Autel-Alpha/7.jpg";
import imageAA8 from "../images/Autel-Alpha/8.jpg";
import imageAA9 from "../images/Autel-Alpha/9.jpg";
import imageAA10 from "../images/Autel-Alpha/10.jpg";
import imageAA11 from "../images/Autel-Alpha/11.jpg";

// // Autel Evo II Pro
// import imageAE1 from "../images/Autel-Evo-II-Pro/1.jpg";
// import imageAE2 from "../images/Autel-Evo-II-Pro/2.jpg";
// import imageAE3 from "../images/Autel-Evo-II-Pro/3.jpg";
// import imageAE4 from "../images/Autel-Evo-II-Pro/4.jpg";
// import imageAE5 from "../images/Autel-Evo-II-Pro/5.jpg";
// import imageAE6 from "../images/Autel-Evo-II-Pro/6.jpg";
// import imageAE7 from "../images/Autel-Evo-II-Pro/7.jpg";
// import imageAE8 from "../images/Autel-Evo-II-Pro/8.jpg";
// import imageAE9 from "../images/Autel-Evo-II-Pro/9.jpg";

// AUTEL Evo II Pro Enterprise
import imageAEE1 from "../images/Autel-Evo-II-Pro-Enterprise/1.jpg";
import imageAEE2 from "../images/Autel-Evo-II-Pro-Enterprise/2.jpg";
import imageAEE3 from "../images/Autel-Evo-II-Pro-Enterprise/3.jpg";
import imageAEE4 from "../images/Autel-Evo-II-Pro-Enterprise/4.jpg";
import imageAEE5 from "../images/Autel-Evo-II-Pro-Enterprise/5.jpg";
import imageAEE6 from "../images/Autel-Evo-II-Pro-Enterprise/6.jpg";
import imageAEE7 from "../images/Autel-Evo-II-Pro-Enterprise/7.jpg";
import imageAEE8 from "../images/Autel-Evo-II-Pro-Enterprise/8.jpg";
import imageAEE9 from "../images/Autel-Evo-II-Pro-Enterprise/9.jpg";
import imageAEE10 from "../images/Autel-Evo-II-Pro-Enterprise/10.jpg";

// AUTEL Evo Max 4T
import imageA4T1 from "../images//AUTEL-Evo-Max-4T/1.jpg";
import imageA4T2 from "../images//AUTEL-Evo-Max-4T/2.jpg";
import imageA4T3 from "../images//AUTEL-Evo-Max-4T/3.jpg";
import imageA4T4 from "../images//AUTEL-Evo-Max-4T/4.jpg";
import imageA4T5 from "../images//AUTEL-Evo-Max-4T/5.jpg";
import imageA4T6 from "../images//AUTEL-Evo-Max-4T/6.jpg";
import imageA4T7 from "../images//AUTEL-Evo-Max-4T/7.jpg";
import imageA4T8 from "../images//AUTEL-Evo-Max-4T/8.jpg";
import imageA4T9 from "../images//AUTEL-Evo-Max-4T/9.jpg";
import imageA4T10 from "../images//AUTEL-Evo-Max-4T/10.jpg";
import imageA4T11 from "../images//AUTEL-Evo-Max-4T/11.jpg";
import imageA4T12 from "../images//AUTEL-Evo-Max-4T/12.jpg";
import imageA4T13 from "../images//AUTEL-Evo-Max-4T/13.jpg";
import imageA4T14 from "../images//AUTEL-Evo-Max-4T/14.jpg";
import imageA4T15 from "../images//AUTEL-Evo-Max-4T/15.jpg";
import imageA4T16 from "../images//AUTEL-Evo-Max-4T/16.jpg";
import imageA4T17 from "../images//AUTEL-Evo-Max-4T/17.jpg";
import imageA4T18 from "../images//AUTEL-Evo-Max-4T/18.jpg";
import imageA4T19 from "../images//AUTEL-Evo-Max-4T/19.jpg";
import imageA4T20 from "../images//AUTEL-Evo-Max-4T/20.jpg";

//Autel evo NANO

// import imageNANO1 from "../images/AUTEL-Evo-NANO/1.jpg";
// import imageNANO2 from "../images/AUTEL-Evo-NANO/2.jpg";
// import imageNANO3 from "../images/AUTEL-Evo-NANO/3.jpg";
// import imageNANO4 from "../images/AUTEL-Evo-NANO/4.jpg";
// import imageNANO5 from "../images/AUTEL-Evo-NANO/5.jpg";
// import imageNANO6 from "../images/AUTEL-Evo-NANO/6.jpg";
// import imageNANO7 from "../images/AUTEL-Evo-NANO/7.jpg";
// import imageNANO8 from "../images/AUTEL-Evo-NANO/8.jpg";
// import imageNANO9 from "../images/AUTEL-Evo-NANO/9.jpg";
// import imageNANO10 from "../images/AUTEL-Evo-NANO/10.jpg";
// import imageNANO11 from "../images/AUTEL-Evo-NANO/11.jpg";
// import imageNANO12 from "../images/AUTEL-Evo-NANO/12.jpg";

//DJI Matrice M30T
import imageM03T1 from "../images/DJI-Matrice-M30T/1.jpg";
import imageM03T2 from "../images/DJI-Matrice-M30T/2.jpg";
import imageM03T3 from "../images/DJI-Matrice-M30T/3.jpg";
import imageM03T4 from "../images/DJI-Matrice-M30T/4.jpg";
import imageM03T5 from "../images/DJI-Matrice-M30T/5.jpg";
import imageM03T6 from "../images/DJI-Matrice-M30T/6.jpg";
import imageM03T7 from "../images/DJI-Matrice-M30T/7.jpg";
import imageM03T8 from "../images/DJI-Matrice-M30T/8.jpg";
import imageM03T9 from "../images/DJI-Matrice-M30T/9.jpg";
import imageM03T10 from "../images/DJI-Matrice-M30T/10.jpg";

//DJI-Mavic-3E

import imageM3E1 from "../images/DJI-Mavic-3E/1.jpg";
import imageM3E2 from "../images/DJI-Mavic-3E/2.jpg";
import imageM3E3 from "../images/DJI-Mavic-3E/3.jpg";
import imageM3E4 from "../images/DJI-Mavic-3E/4.jpg";
import imageM3E5 from "../images/DJI-Mavic-3E/5.jpg";
import imageM3E6 from "../images/DJI-Mavic-3E/6.jpg";
import imageM3E7 from "../images/DJI-Mavic-3E/9.jpg";

//DJI-Mavic-3M

import imageM3M1 from "../images/DJI-Mavic-3M/1.jpg";
import imageM3M2 from "../images/DJI-Mavic-3M/2.jpg";
import imageM3M3 from "../images/DJI-Mavic-3M/3.jpg";
import imageM3M4 from "../images/DJI-Mavic-3M/4.jpg";
import imageM3M5 from "../images/DJI-Mavic-3M/5.jpg";
import imageM3M6 from "../images/DJI-Mavic-3M/6.jpg";
import imageM3M7 from "../images/DJI-Mavic-3M/7.jpg";
import imageM3M8 from "../images/DJI-Mavic-3M/8.jpg";
import imageM3M9 from "../images/DJI-Mavic-3M/9.jpg";
import imageM3M10 from "../images/DJI-Mavic-3M/10.jpg";
import imageM3M11 from "../images/DJI-Mavic-3M/11.jpg";
import imageM3M12 from "../images/DJI-Mavic-3M/12.jpg";
import imageM3M13 from "../images/DJI-Mavic-3M/13.jpg";
import imageM3M14 from "../images/DJI-Mavic-3M/14.jpg";
import imageM3M15 from "../images/DJI-Mavic-3M/15.jpg";
import imageM3M16 from "../images/DJI-Mavic-3M/16.jpg";
import imageM3M17 from "../images/DJI-Mavic-3M/17.jpg";

// DJI-Phantom-P4-Multispectral

import imageP41 from "../images/DJI-Phantom-P4-Multispectral/1.jpg";
import imageP42 from "../images/DJI-Phantom-P4-Multispectral/2.jpg";
import imageP43 from "../images/DJI-Phantom-P4-Multispectral/3.jpg";
import imageP44 from "../images/DJI-Phantom-P4-Multispectral/4.jpg";
import imageP45 from "../images/DJI-Phantom-P4-Multispectral/5.jpg";
import imageP46 from "../images/DJI-Phantom-P4-Multispectral/6.jpg";
import imageP47 from "../images/DJI-Phantom-P4-Multispectral/7.jpg";
import imageP48 from "../images/DJI-Phantom-P4-Multispectral/8.jpg";
import imageP49 from "../images/DJI-Phantom-P4-Multispectral/9.jpg";
import imageP410 from "../images/DJI-Phantom-P4-Multispectral/10.jpg";

const dataProdaja = [
  {
    id: "DJI-Agras-T50",
    name: "DJI Agras T50",
    paketTitle: `Paket:`,
    description: `Please note that the displayed price does not include the batteries!
    `,
    price: ` na upit`,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageT501,
      imageT502,
      imageT503,
      imageT504,
      imageT505,
      imageT506,
      imageT507,
      imageT508,
      imageT509,
      imageT510,
      imageT511,
    ],
    paket: (
      <ul>
        <li>1× Dron DJI Agras T50 </li>
        <li>1× DJI T50 Smart punjač za baterije C10000</li>
        <li>3× DJI T50 Smart baterije DB1560</li>
        <li>1× DJI AGRAS T50 RC Plus Daljinski kontroler</li>
        <li>1× WB37 Akku 2S 4920mAh baterija za daljinski kontroler</li>
        <li>
          1× DJI Crystal Sky/Cendence punjač baterije za daljinski kontroler
        </li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu DJI Agras T50 drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Obuku za upravljanje dronom nakon koje ćete moći potpuno samostalno da
          odgovorite na najteže izazove sa kojim se možete susresti u veoma
          dinamičnom okruženju kakvo je poljoprivredna proivodnja. Obuku
          sprovodi tim stručnjaka zaposlenih na Poljoprivrednom fakultetu,
          Univerziteta u Novom Sadu sa višegodišnjim terenskim iskustvom u ovoj
          oblasti primene dronskih tehnologija. Obuka podrazumeva i višednevni
          praktični rad u realnim uslovima primene!!!
        </li>
        <li>
          Konstantnu podršku u Vašem radu i nakon završene obuke sve dok Vam je
          potrebna i dok ne steknete dovoljno iskustva i sigurnost u radu sa
          novim tehnologijama kao sto je primena drona.
        </li>
        <li>
          Izradu biznis plana i kompletno prikupljanje potrebne dokumentacije
          ukoliko je kupovina drona podržana od strane IPARD programa
          sufinansiranja kupovine opreme za poljoprivrednu proizvodnju.
        </li>
      </ul>
    ),
  },
  {
    id: "DJI-Matrice-M30T",
    name: "DJI Matrice M30T",
    paketTitle: `Paket:`,
    description: `Please note that the displayed price does not include the batteries!
    `,
    price: `9.200,00 € + PDV`,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageM03T1,
      imageM03T2,
      imageM03T3,
      imageM03T4,
      imageM03T5,
      imageM03T6,
      imageM03T7,
      imageM03T8,
      imageM03T9,
      imageM03T10,
    ],
    paket: (
      <ul>
        <li>1× DJI Matrice M30T </li>
        <li>1× DJI BS30 stanica za punjenje</li>
        <li>1× DJI RC Plus (uključuje DJI Pilot 2 aplikaciju)</li>
        <li>1× Transportni kofer</li>
        <li>1× 1671 Propeler (desna rotacija)</li>
        <li>1× 1671 Propeler (leva rotacija)</li>
        <li>1× USB-C kabl</li>
        <li>1× USB-C na USB-C kabl</li>
        <li>1× Set šrafova i alata</li>
        <li>1× DJI Care Enterprise Basic (1 godina)</li>
        <li>
          1× Maintenance Standard servisno održavanje tokom trajanja DJI Care
          Enterprise Basic
        </li>
        <li>1× BS65 punjač za TB65 bateriju</li>
        <li>2× TB30 baterije</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu DJI Matrice M30T drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>
          Edukacija o podešavanju i korišćenju kamera za različite svrhe, poput
          termalne slike, i visoko rezolucijskih fotografija.
        </li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba
        </li>
      </ul>
    ),
  },
  {
    id: "DJI-Matrice-M30T",
    name: "DJI-Matrice-M30T",
    description: `Please note that the displayed price does not include the batteries!
    `,
    price: `9.268,00 € + PDV`,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageM3501,
      imageM3502,
      imageM3503,
      imageM3504,
      imageM3505,
      imageM3506,
      imageM3507,
      imageM3508,
      imageM3509,
    ],
    paket: (
      <ul>
        <li>1× Dron DJI Matrice 350 RTK</li>
        <li>1× RC Plus</li>
        <li>1× WB37 inteligentna baterija</li>
        <li>2× stajni trap</li>
        <li>1× Matrice 350 RTK 2110s propeler (par)</li>
        <li>1× šrafovi i alati</li>
        <li>1× alat za čišćenje</li>
        <li>4× rezervna gimbal zaštita</li>
        <li>1× gumeni poklopac konektora (set)</li>
        <li>1× transportni kofer</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu DJI Matrice 350 RTK drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>
          Edukacija o podešavanju i korišćenju kamera za različite svrhe, poput
          multispektralne analize, termalne slike, i visoko rezolutnih
          fotografija.
        </li>
        <li>
          Obuka u prenosu i analizi prikupljenih podataka sa drona za preciznu
          poljoprivredu, kartiranje terena ili inspekciju objekata.
        </li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),
  },
  {
    id: "DJI-Matrice-M300-RTK",
    name: "DJI Matrice M300 RTK",
    paketTitle: `Paket:`,
    description: `Please note that the displayed price does not include the batteries!
    `,
    price: `12.550,00 € + PDV`,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageM3001,
      imageM3002,
      imageM3003,
      imageM3004,
      imageM3005,
      imageM3006,
      imageM3007,
      imageM3009,
      imageM30010,
      imageM30011,
    ],
    paket: (
      <ul>
        <li>1× Dron DJI Matrice 300 RTK</li>
        <li>1× Daljinski kontroler</li>
        <li>1× USB punjač</li>
        <li>1× USB-C kabl</li>
        <li>1× WB37 baterija</li>
        <li>2× 2110 propelera (CW - u smeru kazaljke na satu)</li>
        <li>2× 2110 propelera (CCW - u suprotnom smeru kazaljke na satu)</li>
        <li>2× Stajni trap</li>
        <li>1× Rezervni poklopci za upravljačke palice (par)</li>
        <li>2× Rezervna zaštita za propelere</li>
        <li>4× Rezervni amortizeri za gimbl</li>
        <li>1× USB kabl (sa 2 tipa A konektora)</li>
        <li>1× Ploča za kalibraciju senzora za vid</li>
        <li>1× Transportni kofer</li>
        <li>1× Kaiš za daljinski upravljač</li>
        <li>1× Gumeni poklopci za priključke (set)</li>
        <li>1× Šrafovi i alati</li>
        <li>1× BS65 punjač za TB65 bateriju</li>
        <li>2× TB65 inteligentna baterija</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu DJI Matrice 300 RTK drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>
          Edukacija o podešavanju i korišćenju kamera za različite svrhe, poput
          multispektralne analize, termalne slike, i visoko rezolutnih
          fotografija.
        </li>
        <li>
          Obuka u prenosu i analizi prikupljenih podataka sa drona za preciznu
          poljoprivredu, kartiranje terena ili inspekciju objekata.
        </li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),
  },
  {
    id: "DJI-Matrice-M350-RTK",
    name: "DJI Matrice M350 RTK",
    price: `12.950,00 € + PDV`,
    paketTitle: `Paket:`,
    paket: (
      <ul>
        <li>1× Dron DJI Matrice 350 RTK</li>
        <li>1× RC Plus</li>
        <li>1× WB37 inteligentna baterija</li>
        <li>2× stajni trap</li>
        <li>1× Matrice 350 RTK 2110s propeler (par)</li>
        <li>1× šrafovi i alati</li>
        <li>1× alat za čišćenje</li>
        <li>4× rezervna gimbal zaštita</li>
        <li>1× gumeni poklopac konektora (set)</li>
        <li>1× transportni kofer</li>
        <li>1× BS65 punjač za TB65 bateriju</li>
        <li>2× TB65 inteligentna baterija</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu DJI Matrice 350 RTK drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>
          Edukacija o podešavanju i korišćenju kamera za različite svrhe, poput
          multispektralne analize, termalne slike, i visoko rezolutnih
          fotografija.
        </li>
        <li>
          Obuka u prenosu i analizi prikupljenih podataka sa drona za preciznu
          poljoprivredu, kartiranje terena ili inspekciju objekata.
        </li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),

    description: `Please note that the displayed price does not include the batteries!
    `,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageM3501,
      imageM3502,
      imageM3503,
      imageM3504,
      imageM3505,
      imageM3506,
      imageM3507,
      imageM3508,
      imageM3509,
    ],

    description1:
      "Matrice 350 RTK je mnogo više od obične nadogradnje velikog drona. Ovaj dron sledeće generacije ponovo postavlja nove standarde za celu industriju. Obezbeđuje ih poboljšani sistem za video prenos, potpuno novi sistem kontrole, efikasniji sistem baterija, naprednije sigurnosne funkcije, kao i izuzetno robusne opcije za nosivost i proširenja. Iskusite inovativnu snagu koja se otkriva pri svakom letu.",
    stat1: "Maksimalno vreme leta: 55 minuta",
    stat2: "Zaštita klase IP55",
    stat3: "DJI O3 Enterprise Transmission",
    stat4: "DJI RC Plus daljinski upravljač",
    stat5: "400 ciklusa baterije",
    stat6: "Senzori i pozicioniranje u 6 pravaca",
    stat7: "Noćna FPV kamera",
    stat8: "Podrška za više korisnih tereta",
    description2:
      "Snažne performanse letenja Robustno izrađeni Matrice 350 RTK ima snažan pogon, visok stepen zaštite i izuzetne karakteristike letenja, što omogućava lako suočavanje sa raznim izazovima.",
    stat9: "Maksimalno vreme leta: 55 minuta",
    stat10: "Klasa zaštite: IP55",
    stat11: "Maksimalna nosivost: 2.7 kg",
    stat12: "Otpornost na vetar: do 12 m/s",
    stat13: "Radna temperatura: od -20 °C do 50 °C",
    stat14: "Maksimalna visina leta: 7,000 m",
    // stat15: "Noćna FPV kamera",

    title2: "Stabilan zahvaljujući novom sistemu prenosa",
    description3:
      "O3 Enterprise Transmission Matrice 350 RTK dolazi sa DJI O3 Enterprise sistemom prenosa koji podržava 3-kanalni 1080p HD prenos uživo i domet prenosa do 20 km. Letelica i daljinski upravljač imaju inteligentni sistem sa 4 antene koji automatski bira dve optimalne antene za prenos signala, dok sve četiri antene istovremeno primaju signale. Na ovaj način se značajno smanjuju smetnje i poboljšava stabilnost prenosa.",
    title3: "Novi daljinski upravljač sa više opcija",
    description4:
      "DJI RC Plus DJI RC Plus je opremljen osvetljenim 7-inčnim ekranom, podržava dvostruki način rada i dolazi sa eksternom WB37 baterijom, koja omogućava rad do šest sati, pokrivajući širok spektar zahteva.Spreman za ekstremne uslove Sa zaštitom klase IP54, DJI RC Plus je otporan na prašinu i vodu i radi pouzdano čak i u lošim vremenskim uslovima. Radna temperatura je od -20 °C do 50 °C, što omogućava upotrebu u izuzetno vrućim ili hladnim okruženjima.Gladka i efikasna kontrola Više dugmadi i prekidača na prednjoj, zadnjoj i gornjoj strani daljinskog upravljača omogućavaju brzu i jednostavnu upotrebu. Funkcije dugmadi se mogu prilagoditi navikama korisnika, što omogućava intuitivniju kontrolu.",
    title4: "Poboljšan sistem baterija",
    description5:
      "Novi dvostruki sistem Matrice 350 RTK je opremljen novim dvostrukim TB65 sistemom baterija, koji podržava zamenu baterija u toku rada i omogućava više letova bez gašenja drona. Baterija se može puniti do 400 puta, što značajno smanjuje troškove po jednom letu.Sledeća generacija stanica za punjenje Inteligentna BS65 stanica za punjenje je kompletno rešenje za punjenje, skladištenje i transport baterija.Višestruki načini punjenja U režimu skladištenja, baterija se puni do 50%, što je optimalno za dugoročno skladištenje. U režimu spremnosti za let, više setova baterija se brzo puni do 90%, omogućavajući brzi povratak u vazduh i produžava životni vek baterije.Pametno upravljanje Kada je stanica za punjenje baterija povezana sa DJI RC Plus, aplikacija DJI Pilot 2 prikazuje nivo baterije i status funkcionalnosti. Takođe, možete odrediti broj dana za samopražnjenje, ažurirati softver, izvoziti zapise i pristupati brzim koracima jednostavnim dodirom.",
    title5: "Poboljšana sigurnost leta",
    description6:
      "Detekcija zaključavanja ruku Matrice 350 RTK ima novu funkciju detekcije zaključavanja, koja sprečava rizik od otključanih ramova, omogućavajući vam da se fokusirate na let bez brige.Noćna FPV kamera Zahvaljujući odličnim noćnim mogućnostima, FPV kamera jasno prepoznaje okolinu i prepreke tokom noćnih letova. U kombinaciji sa funkcijom PinPoint, omogućava bolju navigaciju za sigurnije letove i lakše pružanje usluga noću.Senzori i pozicioniranje u 6 pravaca Matrice 350 RTK je opremljen binokularnim sistemom za pregled i infracrvenim senzorima za detekciju, pozicioniranje i izbegavanje prepreka u šest pravaca, pružajući sveobuhvatnu zaštitu tokom leta.CSM radar Sa ugrađenim CSM radarom, Matrice 350 RTK može detektovati suptilne prepreke u gornjem delu ili u horizontalnom dometu od 360° i izbegavati ih na vreme. Efektivni domet je do 30 m, što dodatno poboljšava sigurnost leta.Snažni korisni tereti Matrice 350 RTK podržava individualne gimbale okrenute nadole, dvostruke gimbale i jedan gimbal okrenut nagore, sa maksimalnom nosivošću od 2.7 kg, omogućavajući transport do tri tereta istovremeno, što zadovoljava zahteve različitih scenarija, poput javne bezbednosti, inspekcija i mapiranja.",
    title6: "Inteligentne funkcije",
    description7:
      "Visokoprecizno mapiranje Matrice 350 RTK podržava misije sa tačkama, mapiranje, misije pod uglom i linearne letove.",
    det1t: (
      <p>
        <b>Dimenzije (otvoren, bez propelera): </b>810 x 670 x 430 mm (D-Š-V)
      </p>
    ),
    det2t: (
      <p>
        <b>Dimenzije (sklopljen, sa propelerima): </b> 430 x 420 x 430 mm
        (D-Š-V)
      </p>
    ),
    det3t: (
      <p>
        <b>Dijagonalna udaljenost osa: </b>895 mm
      </p>
    ),
    det4t: (
      <p>
        <b>Težina (sa jednim donjim gimbal nosačem): </b>Bez baterija oko 3,77
        kg; Sa dve TB65 baterije oko 6,47 kg
      </p>
    ),
    det5t: (
      <p>
        <b>Maksimalna nosivost jednog gimbal nosača: </b>960 g
      </p>
    ),
    det6t: (
      <p>
        <b>Maksimalna težina pri poletanju: </b>9,2 kg
      </p>
    ),
    det7t: (
      <p>
        <b>Radna frekvencija: </b>2,400 do 2,4835 GHz; 5,150 do 5,250 GHz (CE:
        5.170 do 5.250 GHz); 5,725 do 5,850 GHz
      </p>
    ),
    det1p:
      "U nekim zemljama i regionima, 5,1-GHz i 5,8-GHz frekventni opsezi su zabranjeni, ili je 5,1 GHz frekvencija dozvoljena samo za upotrebu u zatvorenom prostoru. Obratite pažnju na lokalne zakone i propise.",
  },
  {
    id: "DJI-Mavic-3M",
    name: "DJI Mavic 3M",
    price: `5.500,00 € + PDV`,

    paket: (
      <ul>
        <li>1× Dron DJI Mavic 3M multispectral</li>
        <li>1× RTK modul</li>
        <li>1× MicroSD kartica</li>
        <li>1× Daljinski upravljač</li>
        <li>3× Propeleri (par)</li>
        <li>1× Kabl za napajanje</li>
        <li>1× USB-C kabl</li>
        <li>1× USB-C na USB-C kabl</li>
        <li>1× Transportni kofer</li>
        <li>1× Šrafovi i alati</li>
        <li>1× punjač za baterije DJI Mavic 3M</li>
        <li>3× baterija za DJI Mavic 3M</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu DJI Mavic 3M drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>
          {" "}
          Edukacija o podešavanju i korišćenju kamera za različite svrhe, poput
          multispektralne analize i visoko rezolucijskih fotografija
        </li>
        <li>
          Obuka u prenosu i analizi prikupljenih podataka sa drona za preciznu
          poljoprivredu, kartiranje mutispektralnih i RGB mapa terena.
        </li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),

    description: `Please note that the displayed price does not include the batteries!
    `,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageM3M1,
      imageM3M2,
      imageM3M3,
      imageM3M4,
      imageM3M5,
      imageM3M6,
      imageM3M7,
      imageM3M8,
      imageM3M9,
      imageM3M10,
      imageM3M11,
      imageM3M12,
      imageM3M13,
      imageM3M14,
      imageM3M15,
      imageM3M16,
      imageM3M17,
    ],
  },
  {
    id: "DJI-Phantom-P4-Multispectral",
    name: "DJI Phantom P4 Multispecral",
    price: `6.190,00 € + PDV`,

    paket: (
      <ul>
        <li>1× Dron Phantom P4 Multispecral</li>
        <li>1× Daljinski upravljač bez ekrana</li>
        <li>4× Par propelera</li>
        <li>2× Pametna baterija za letelicu</li>
        <li>1× Hub za punjenje pametne baterije</li>
        <li>1× Adapter za napajanje</li>
        <li>1× Kabl za napajanje za adapter</li>
        <li>1× Micro-USB kabl</li>
        <li>1× USB kabl</li>
        <li>1× MicroSD kartica</li>
        <li>1× Transportna kutija</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu DJI Phantom P4 Multispecral drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>
          {" "}
          Edukacija o podešavanju i korišćenju kamera za različite svrhe, poput
          multispektralne analize i visoko rezolucijskih fotografija.
        </li>
        <li>
          Obuka u prenosu i analizi prikupljenih podataka sa drona za preciznu
          poljoprivredu, kartiranje mutispektralnih i RGB mapa terena.
        </li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),

    description: `Please note that the displayed price does not include the batteries!
    `,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageP41,
      imageP42,
      imageP43,
      imageP44,
      imageP45,
      imageP46,
      imageP47,
      imageP48,
      imageP49,
      imageP410,
    ],
  },
  {
    id: "Autel-Alpha",
    name: "Autel Alpha",
    price: `19.200,00 € + PDV`,

    paket: (
      <p>
        <b>Autel Alpha</b> je inteligentna industrijska bespilotna letelica za
        višestruke namene. Sadrži značajna unapređenja u autonomnim
        sposobnostima letenja, otpornosti na smetnje, sposobnosti izbegavanja
        prepreka, tehnologiji prenosa videa i sistemima baterija, što daje
        robusne performanse platformi za let. Sa sklopivim dizajnom i IP55
        stepenom zaštite, prilagođena je za rad u izazovnim okruženjima.
        Ugrađeni RTK dvostruki antenski sistem obezbeđuje preciznu kontrolu u
        milimetrima pri izvođenju misija. U kombinaciji sa najnovijom DG-L35T
        gimbalom, poseduje 560x hibridnu zoom kameru, dve termalne kamere,
        širokougaonu kameru za vidljivu svetlost i laserski daljinomer. Dve
        termalne kamere omogućavaju pregled iz blizine i detaljno posmatranje na
        daljinu, omogućavajući rad u scenarijima gde se može prepoznati osoba na
        udaljenosti do 8 kilometara, pružajući profesionalna i sveobuhvatna
        rešenja za primene kao što su javna bezbednost, inspekcija energije i
        upravljanje u vanrednim situacijama.
      </p>
    ),
    dodatakTitle: `Uz kupovinu Autel - Alpha drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>Edukacija o podešavanju i korišćenju kamera za različite svrhe,</li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),

    description: `Please note that the displayed price does not include the batteries!
    `,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageAA1,
      imageAA2,
      imageAA3,
      imageAA4,
      imageAA5,
      imageAA6,
      imageAA7,
      imageAA8,
      imageAA9,
      imageAA10,
      imageAA11,
    ],
  },
  {
    id: "DJI-Mavic-3E",
    name: "DJI Mavic 3E",
    price: `3.850,00 € + PDV`,

    paket: (
      <ul>
        <li>1× Dron DJI Mavic 3E</li>
        <li>1× Mavic 3 inteligentna baterija za let</li>
        <li>1× MicroSD kartica 64GB</li>
        <li>1× Gimbal zaštita</li>
        <li>1× Par propelera za Mavic 3 Enterprise</li>
        <li>1× DJI RC Pro Enterprise</li>
        <li>1× Šrafciger</li>
        <li>1× DJI USB-C adapter za napajanje (100W)</li>
        <li>1× AC kabl za 100W adapter</li>
        <li>1× USB-C kabl</li>
        <li>1× USB-C na USB-C kabl (set)</li>
        <li>1× Tvrdi transportni kofer</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu DJI Mavic 3E drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>
          {" "}
          Edukacija o podešavanju i korišćenju kamera za različite svrhe i
          visoko rezolucijskih fotografija.
        </li>
        <li>
          Obuka u prenosu i analizi prikupljenih podataka sa drona za preciznu
          poljoprivredu, kartiranje terena ili inspekciju objekata.
        </li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),

    description: `Please note that the displayed price does not include the batteries!
    `,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageM3E1,
      imageM3E2,
      imageM3E3,
      imageM3E4,
      imageM3E5,
      imageM3E6,
      imageM3E7,
    ],
  },

  // {
  //   id: "Autel-Evo-II-Pro",
  //   name: "AUTEL Evo II Pro",
  //   price: `0000,00 € + PDV`,
  //   paketTitle: `Paket:`,
  //   paket: (
  //     <ul>
  //       <li>1× Dron EVO II Pro</li>
  //       <li>1× Daljinski upravljač</li>
  //       <li>3× Par propelera</li>
  //       <li>1× Punjač</li>
  //       <li>1× Kabl za punjenje</li>
  //       <li>1× Kabl za punjenje daljinskog upravljača</li>
  //       <li>2× USB kabl (2 komada: Micro Tip B i Tip C)</li>
  //       <li>1× Inteligentna baterija za let</li>
  //     </ul>
  //   ),
  //   dodatakTitle: `Uz kupovinu AUTEL Evo II Pro drona kod nas dobijate:`,
  //   dodatak: (
  //     <ul>
  //       <li>
  //         Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
  //         različite režime letenja i osnovne manevre.
  //       </li>
  //       <li>Edukacija o podešavanju i korišćenju kamera za različite svrhe.</li>
  //       <li>
  //         Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
  //         bi se osigurala dugotrajna i pouzdana upotreba.
  //       </li>
  //     </ul>
  //   ),
  //   description: `Please note that the displayed price does not include the batteries!
  //   `,
  //   pdv: `Pdv i dostava nisu ukljuceni u cenu`,
  //   images: [
  //     imageAE1,
  //     imageAE2,
  //     imageAE3,
  //     imageAE4,
  //     imageAE5,
  //     imageAE6,
  //     imageAE7,
  //     imageAE8,
  //     imageAE9,
  //   ],
  // },
  {
    id: "Autel-Evo-II-Pro-Enterprise",
    name: "AUTEL Evo II Pro Enterprise",
    price: `3.699,00 € + PDV`,
    paketTitle: `Paket:`,
    paket: (
      <ul>
        <li>1× Dron EVO II Enterprise</li>
        <li>1× Smart kontroler V2</li>
        <li>2× Rezervna baterija</li>
        <li>1× Punjač</li>
        <li>1× Punjač za Smart kontroler V2</li>
        <li>1× Kabl za punjenje</li>
        <li>1× Hub za punjenje</li>
        <li>1× Punjač za auto</li>
        <li>1× Zvučnik</li>
        <li>1× Reflektor</li>
        <li>1× Trepćuće svetlo</li>
        <li>1× Kaiš za nošenje</li>
        <li>2× Upravljačka palica</li>
        <li>1× Zaštita za Smart kontroler V2</li>
        <li>2× Propeler (par)</li>
        <li>1× Čvrst kofer</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu AUTEL Evo II Pro Enterprise drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>Edukacija o podešavanju i korišćenju kamera za različite svrhe</li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),
    description: `Please note that the displayed price does not include the batteries!
    `,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageAEE1,
      imageAEE2,
      imageAEE3,
      imageAEE4,
      imageAEE5,
      imageAEE6,
      imageAEE7,
      imageAEE8,
      imageAEE9,
      imageAEE10,
    ],
  },
  {
    id: "Autel-Evo-Max-4T",
    name: "AUTEL Evo Max 4T",
    price: `8.200,00 € + PDV`,
    paketTitle: `Paket:`,
    paket: (
      <ul>
        <li>
          1× Dron (uključujući bateriju, propelere, kardansku osovinu, poklopac
          za osovinu, 64G SD karticu)
        </li>
        <li>1× Punjač za bateriju + kabl za napajanje</li>
        <li>1× Rezervni propeler (par)</li>
        <li>1× Šrafciger</li>
        <li>1× Smart kontroler V3 (uključujući upravljačke palice)</li>
        <li>1× Traka za nošenje Smart kontrolera</li>
        <li>1× Poklopac za Smart kontroler</li>
        <li>1× Rezervni džojstik za Smart kontroler (par)</li>
        <li>1× Punjač za daljinski kontroler</li>
        <li>1× Kabl za punjenje daljinskog kontrolera</li>
        <li>1× Podatkovni kabl</li>
        <li>1× Dokumentacija • Brzi vodič za početak • Lista pakovanja</li>
        <li>1× Čvrst kofer</li>
      </ul>
    ),
    dodatakTitle: `Uz kupovinu AUTEL Evo Max 4T drona kod nas dobijate:`,
    dodatak: (
      <ul>
        <li>
          Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
          različite režime letenja i osnovne manevre.
        </li>
        <li>Edukacija o podešavanju i korišćenju kamera za različite svrhe.</li>
        <li>
          Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
          bi se osigurala dugotrajna i pouzdana upotreba.
        </li>
      </ul>
    ),
    description: `Please note that the displayed price does not include the batteries!
    `,
    pdv: `Pdv i dostava nisu ukljuceni u cenu`,
    images: [
      imageA4T1,
      imageA4T2,
      imageA4T3,
      imageA4T4,
      imageA4T5,
      imageA4T6,
      imageA4T7,
      imageA4T8,
      imageA4T9,
      imageA4T10,
      imageA4T11,
      imageA4T12,
      imageA4T13,
      imageA4T14,
      imageA4T15,
      imageA4T16,
      imageA4T17,
      imageA4T18,
      imageA4T19,
      imageA4T20,
    ],
  },

  // {
  //   id: "Autel-Evo-NANO",
  //   name: "AUTEL Evo NANO",
  //   price: `0000,00 € + PDV`,

  //   paket: (
  //     <div>
  //       <p>
  //         EVO Nano predstavlja kompaktan i snažan dron, dizajniran posebno za
  //         različita okruženja. Spojem napredne tehnologije i jednostavnosti za
  //         korišćenje, pruža izuzetno iskustvo letenja kako za početnike, tako i
  //         za iskusne pilote dronova.{" "}
  //       </p>
  //       <p>
  //         Dron impresionira svojim kompaktnim dizajnom, što ga čini veoma
  //         prenosivim i jednostavnim za rukovanje. Uprkos malim dimenzijama, EVO
  //         Nano pruža izvanredne performanse u letu, što ga čini idealnim za
  //         avanture na otvorenom, vazdušnu fotografiju ili istraživanje uskih
  //         prostora.
  //       </p>
  //       <p>
  //         Opremljen visokokvalitetnim kamerama, EVO Nano omogućava oštro
  //         snimanje 4K video zapisa i fotografija visoke rezolucije. Dron se
  //         ističe preciznom kontrolom i stabilnošću u letu, bilo tokom mirnih
  //         letova ili složenijih manevara. Inteligentni režimi letenja kao što su{" "}
  //         <b>PRATI ME, NAVIGACIJA PO TAČKAMA i AUTOMATSKI POVRATAK</b>{" "}
  //         unapređuju i pojednostavljuju iskustvo letenja.
  //       </p>
  //       <p>
  //         EVO Nano pokreću snažne baterije koje omogućavaju duže vreme letenja.
  //         Intuitivna kontrola putem mobilne aplikacije omogućava lako
  //         upravljanje dronom i kreiranje zadivljujućih snimaka iz vazduha.
  //       </p>
  //       <p>
  //         Bilo da istražujete zadivljujuće pejzaže, beležite dinamične sportske
  //         scene ili jednostavno uživate u slobodi letenja, Autel EVO Nano vam
  //         pruža priliku da doživite avanture iz novih perspektiva.
  //       </p>
  //       <p>
  //         Dostupan u bojama: arktička bela, Autel narandžasta, tamno siva i
  //         vatreno crvena.
  //       </p>
  //     </div>
  //   ),
  //   dodatakTitle: `Uz kupovinu AUTEL Evo NANO drona kod nas dobijate:`,
  //   dodatak: (
  //     <ul>
  //       <li>
  //         Detaljna obuka za sigurno i efikasno upravljanje dronom, uključujući
  //         različite režime letenja i osnovne manevre
  //       </li>
  //       <li>
  //         Edukacija o podešavanju i korišćenju kamera za različite svrhe, .
  //       </li>
  //       <li>
  //         Servis i održavanje: Saveti i podrška za redovno održavanje drona kako
  //         bi se osigurala dugotrajna i pouzdana upotreba.
  //       </li>
  //     </ul>
  //   ),
  //   description: `Please note that the displayed price does not include the batteries!
  //   `,
  //   pdv: `Pdv i dostava nisu ukljuceni u cenu`,
  //   images: [
  //     imageNANO1,
  //     imageNANO2,
  //     imageNANO3,
  //     imageNANO4,
  //     imageNANO5,
  //     imageNANO6,
  //     imageNANO7,
  //     imageNANO8,
  //     imageNANO9,
  //     imageNANO10,
  //     imageNANO11,
  //     imageNANO12,
  //   ],
  //   description: `Please note that the displayed price does not include the batteries!
  //   `,
  //   pdv: `Pdv i dostava nisu ukljuceni u cenu`,
  // },
];

export const dataProdajaOpis = [
  {
    id: "DJI-Agras-T50",
    // option: [
    //   <OpisContainer>
    //     <PrviOpisContainer>
    //       <ImeArtikla>DJI-Agras-T50</ImeArtikla>

    //       <>
    //         <PrviOpisArtikla>
    //           DJI AGRAS T50 unapređuje poljoprivredne operacije sa dronom nove
    //           generacije. Opremljen je sa četiri snažna sistema dvostrukih
    //           rotora koji obezbeđuje stabilnost prilikom nošenja tereta od 40 kg
    //           za prskanje ili 50 kg za rasipanje
    //         </PrviOpisArtikla>
    //         <PrveVrednostiArtiklaContainer>
    //           <MaxVremeLeta>Maksimalno vreme leta: 55 minuta</MaxVremeLeta>
    //           <ZastitaKlase>Zaštita klase IP55</ZastitaKlase>
    //           <Transmisition>DJI O3 Enterprise Transmission</Transmisition>
    //           <DaljinskiUpravljac>
    //             DJI RC Plus daljinski upravljač
    //           </DaljinskiUpravljac>
    //           <BaterijaArtikla>400 ciklusa baterije</BaterijaArtikla>
    //           <SenzoriArtikla>
    //             Senzori i pozicioniranje u 6 pravaca
    //           </SenzoriArtikla>
    //           <NocnaKamera>Noćna FPV kamera</NocnaKamera>
    //           <Tereni>Podrška za više korisnih tereta</Tereni>
    //         </PrveVrednostiArtiklaContainer>
    //         <DrugiOpisArtikla></DrugiOpisArtikla>
    //         <DrugeVrednostiArtiklaContainer>
    //           <MaxVremeLeta>koka</MaxVremeLeta>
    //           <KlasaZastite>koka</KlasaZastite>
    //           <MaxNosivost>koka</MaxNosivost>
    //           <OtpNaVetar>koka</OtpNaVetar>
    //           <RadnaTemp>koka</RadnaTemp>
    //           <MaxVisina>koka</MaxVisina>
    //         </DrugeVrednostiArtiklaContainer>
    //       </>
    //     </PrviOpisContainer>

    //     <OstaliOpisContainer>
    //       <DrugiNaslov></DrugiNaslov>

    //       <>
    //         <DrugiOpisDescription></DrugiOpisDescription>
    //         <TreciNaslov></TreciNaslov>
    //         <TreciOpisDescription></TreciOpisDescription>
    //         <CetvrtiNaslov></CetvrtiNaslov>
    //         <CetvrtiOpisDescription></CetvrtiOpisDescription>
    //         <PetiNaslov></PetiNaslov>
    //         <PetiOpisDescription></PetiOpisDescription>
    //         <SestiNaslov></SestiNaslov>
    //         <SestiOpisDescription></SestiOpisDescription>
    //       </>
    //     </OstaliOpisContainer>
    //   </OpisContainer>,
    // ],
  },
  {
    id: "DJI-Matrice-M350-RTK",
    // option: [
    //   <OpisContainer>
    //     <PrviOpisContainer>
    //       <ImeArtikla>DJI-Matrice-M350-RTK</ImeArtikla>

    //       <>
    //         <PrviOpisArtikla>
    //           Matrice 350 RTK je mnogo više od obične nadogradnje velikog drona.
    //           Ovaj dron sledeće generacije ponovo postavlja nove standarde za
    //           celu industriju. Obezbeđuje ih poboljšani sistem za video prenos,
    //           potpuno novi sistem kontrole, efikasniji sistem baterija,
    //           naprednije sigurnosne funkcije, kao i izuzetno robusne opcije za
    //           nosivost i proširenja. Iskusite inovativnu snagu koja se otkriva
    //           pri svakom letu.
    //         </PrviOpisArtikla>
    //         <PrveVrednostiArtiklaContainer>
    //           <MaxVremeLeta>Maksimalno vreme leta: 55 minuta</MaxVremeLeta>
    //           <ZastitaKlase>Zaštita klase IP55</ZastitaKlase>
    //           <Transmisition>DJI O3 Enterprise Transmission</Transmisition>
    //           <DaljinskiUpravljac>
    //             DJI RC Plus daljinski upravljač
    //           </DaljinskiUpravljac>
    //           <BaterijaArtikla>400 ciklusa baterije</BaterijaArtikla>
    //           <SenzoriArtikla>
    //             Senzori i pozicioniranje u 6 pravaca
    //           </SenzoriArtikla>
    //           <NocnaKamera>Noćna FPV kamera</NocnaKamera>
    //           <Tereni>Podrška za više korisnih tereta</Tereni>
    //         </PrveVrednostiArtiklaContainer>
    //         <DrugiOpisArtikla>
    //           Snažne performanse letenja Robustno izrađeni Matrice 350 RTK ima
    //           snažan pogon, visok stepen zaštite i izuzetne karakteristike
    //           letenja, što omogućava lako suočavanje sa raznim izazovima.",
    //           title2: "Stabilan zahvaljujući novom sistemu prenosa
    //         </DrugiOpisArtikla>
    //         <DrugeVrednostiArtiklaContainer>
    //           <MaxVremeLeta>Maksimalno vreme leta: 55 minuta</MaxVremeLeta>
    //           <KlasaZastite>Klasa zaštite: IP55</KlasaZastite>
    //           <MaxNosivost>Maksimalna nosivost: 2.7 kg</MaxNosivost>
    //           <OtpNaVetar>Otpornost na vetar: do 12 m/s</OtpNaVetar>
    //           <RadnaTemp>Radna temperatura: od -20 °C do 50 °C</RadnaTemp>
    //           <MaxVisina>Maksimalna visina leta: 7,000 m</MaxVisina>
    //         </DrugeVrednostiArtiklaContainer>
    //       </>
    //     </PrviOpisContainer>

    //     <OstaliOpisContainer>
    //       <DrugiNaslov></DrugiNaslov>

    //       <>
    //         <DrugiOpisDescription>
    //           O3 Enterprise Transmission Matrice 350 RTK dolazi sa DJI O3
    //           Enterprise sistemom prenosa koji podržava 3-kanalni 1080p HD
    //           prenos uživo i domet prenosa do 20 km. Letelica i daljinski
    //           upravljač imaju inteligentni sistem sa 4 antene koji automatski
    //           bira dve optimalne antene za prenos signala, dok sve četiri antene
    //           istovremeno primaju signale. Na ovaj način se značajno smanjuju
    //           smetnje i poboljšava stabilnost prenosa.
    //         </DrugiOpisDescription>
    //         <TreciNaslov>Novi daljinski upravljač sa više opcija</TreciNaslov>
    //         <TreciOpisDescription>
    //           DJI RC Plus DJI RC Plus je opremljen osvetljenim 7-inčnim ekranom,
    //           podržava dvostruki način rada i dolazi sa eksternom WB37
    //           baterijom, koja omogućava rad do šest sati, pokrivajući širok
    //           spektar zahteva.Spreman za ekstremne uslove Sa zaštitom klase
    //           IP54, DJI RC Plus je otporan na prašinu i vodu i radi pouzdano čak
    //           i u lošim vremenskim uslovima. Radna temperatura je od -20 °C do
    //           50 °C, što omogućava upotrebu u izuzetno vrućim ili hladnim
    //           okruženjima.Gladka i efikasna kontrola Više dugmadi i prekidača na
    //           prednjoj, zadnjoj i gornjoj strani daljinskog upravljača
    //           omogućavaju brzu i jednostavnu upotrebu. Funkcije dugmadi se mogu
    //           prilagoditi navikama korisnika, što omogućava intuitivniju
    //           kontrolu.
    //         </TreciOpisDescription>
    //         <CetvrtiNaslov>Poboljšan sistem baterija</CetvrtiNaslov>
    //         <CetvrtiOpisDescription>
    //           Novi dvostruki sistem Matrice 350 RTK je opremljen novim
    //           dvostrukim TB65 sistemom baterija, koji podržava zamenu baterija u
    //           toku rada i omogućava više letova bez gašenja drona. Baterija se
    //           može puniti do 400 puta, što značajno smanjuje troškove po jednom
    //           letu.Sledeća generacija stanica za punjenje Inteligentna BS65
    //           stanica za punjenje je kompletno rešenje za punjenje, skladištenje
    //           i transport baterija.Višestruki načini punjenja U režimu
    //           skladištenja, baterija se puni do 50%, što je optimalno za
    //           dugoročno skladištenje. U režimu spremnosti za let, više setova
    //           baterija se brzo puni do 90%, omogućavajući brzi povratak u vazduh
    //           i produžava životni vek baterije.Pametno upravljanje Kada je
    //           stanica za punjenje baterija povezana sa DJI RC Plus, aplikacija
    //           DJI Pilot 2 prikazuje nivo baterije i status funkcionalnosti.
    //           Takođe, možete odrediti broj dana za samopražnjenje, ažurirati
    //           softver, izvoziti zapise i pristupati brzim koracima jednostavnim
    //           dodirom.
    //         </CetvrtiOpisDescription>
    //         <PetiNaslov>Poboljšana sigurnost leta</PetiNaslov>
    //         <PetiOpisDescription>
    //           Detekcija zaključavanja ruku Matrice 350 RTK ima novu funkciju
    //           detekcije zaključavanja, koja sprečava rizik od otključanih
    //           ramova, omogućavajući vam da se fokusirate na let bez brige.Noćna
    //           FPV kamera Zahvaljujući odličnim noćnim mogućnostima, FPV kamera
    //           jasno prepoznaje okolinu i prepreke tokom noćnih letova. U
    //           kombinaciji sa funkcijom PinPoint, omogućava bolju navigaciju za
    //           sigurnije letove i lakše pružanje usluga noću.Senzori i
    //           pozicioniranje u 6 pravaca Matrice 350 RTK je opremljen
    //           binokularnim sistemom za pregled i infracrvenim senzorima za
    //           detekciju, pozicioniranje i izbegavanje prepreka u šest pravaca,
    //           pružajući sveobuhvatnu zaštitu tokom leta.CSM radar Sa ugrađenim
    //           CSM radarom, Matrice 350 RTK može detektovati suptilne prepreke u
    //           gornjem delu ili u horizontalnom dometu od 360° i izbegavati ih na
    //           vreme. Efektivni domet je do 30 m, što dodatno poboljšava
    //           sigurnost leta.Snažni korisni tereti Matrice 350 RTK podržava
    //           individualne gimbale okrenute nadole, dvostruke gimbale i jedan
    //           gimbal okrenut nagore, sa maksimalnom nosivošću od 2.7 kg,
    //           omogućavajući transport do tri tereta istovremeno, što zadovoljava
    //           zahteve različitih scenarija, poput javne bezbednosti, inspekcija
    //           i mapiranja.
    //         </PetiOpisDescription>
    //         <SestiNaslov>Inteligentne funkcije</SestiNaslov>
    //         <SestiOpisDescription>
    //           Visokoprecizno mapiranje Matrice 350 RTK podržava misije sa
    //           tačkama, mapiranje, misije pod uglom i linearne letove.
    //         </SestiOpisDescription>
    //       </>
    //     </OstaliOpisContainer>
    //   </OpisContainer>,
    // ],
  },
];

export default dataProdaja;
